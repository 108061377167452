import axios from "axios";
 
export default {
  init: function (appCreated) {
    return new Promise((resolve, reject) => {
      switch (appCreated) {
        case false:
          Promise.all([
          
          ])
            .then((values) => {
             
              resolve();
            })
            .catch((err) => {
              // console.log(err);
              resolve();
            });
          break;
        case true:
          console.log("vue created, but waiting for next initialization steps and data");
          setTimeout((_) => resolve("Mounted / shown when app ready"), 10);
      }
    });
  },
};
