import Vue from "vue";
import VueRouter from "vue-router";
import About from "@/views/eteam/about.vue"; 
import Eteam from "@/views/eteam/eteam.vue"; 
import Levelup from "@/views/eteam/levelup.vue"; 
import Benefits from "@/views/eteam/benefits.vue"; 
import Jobs from "@/views/eteam/jobs.vue"; 

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "eteam",
    component: Eteam,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/levelup",
    name: "levelup",
    component: Levelup,
  },
  {
    path: "/benefits",
    name: "benefits",
    component: Benefits,
  },
  {
    path: "/jobs",
    name: "jobs",
    component: Jobs,
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
