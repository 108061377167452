<template>
    <div class="footer-wrapper">
        <div class="container">
                <div class="footer-row">
                    <div class="column-item">
                        <div class="socials">
                            <a target="_blank" href="https://instagram.com/e.team.ge">
                                <svg id="Group_16" data-name="Group 16" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path id="Path_29" data-name="Path 29" d="M0,0H24V24H0Z" fill="none"/>
                                    <path id="Path_30" data-name="Path 30" d="M12,2c2.717,0,3.056.01,4.122.06a7.367,7.367,0,0,1,2.428.465,4.876,4.876,0,0,1,1.772,1.153A4.908,4.908,0,0,1,21.475,5.45a7.387,7.387,0,0,1,.465,2.428C21.987,8.944,22,9.283,22,12s-.01,3.056-.06,4.122a7.393,7.393,0,0,1-.465,2.428,5.106,5.106,0,0,1-2.925,2.925,7.387,7.387,0,0,1-2.428.465c-1.066.047-1.4.06-4.122.06s-3.056-.01-4.122-.06a7.393,7.393,0,0,1-2.428-.465A5.106,5.106,0,0,1,2.525,18.55a7.361,7.361,0,0,1-.465-2.428C2.013,15.056,2,14.717,2,12s.01-3.056.06-4.122A7.361,7.361,0,0,1,2.525,5.45,4.88,4.88,0,0,1,3.678,3.678,4.9,4.9,0,0,1,5.45,2.525,7.361,7.361,0,0,1,7.878,2.06C8.944,2.013,9.283,2,12,2Zm0,5a5,5,0,1,0,5,5A5,5,0,0,0,12,7Zm6.5-.25A1.25,1.25,0,1,0,17.25,8,1.25,1.25,0,0,0,18.5,6.75ZM12,9a3,3,0,1,1-3,3A3,3,0,0,1,12,9Z" transform="translate(0 0)" fill="#fff"/>
                                </svg>

                            </a>
                            <a target="_blank" href="https://www.facebook.com/eteam.ge">
                                <svg id="Group_17" data-name="Group 17" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path id="Path_31" data-name="Path 31" d="M0,0H24V24H0Z" fill="none"/>
                                    <path id="Path_32" data-name="Path 32" d="M12,2a10,10,0,0,0-1.562,19.879V14.89H7.9V12h2.54V9.8a3.528,3.528,0,0,1,3.777-3.89,15.393,15.393,0,0,1,2.238.195v2.46h-1.26a1.445,1.445,0,0,0-1.63,1.562V12h2.773l-.443,2.89h-2.33v6.989A10,10,0,0,0,12,2Z" fill="#fff"/>
                                </svg>
                            </a>
                            <a target="_blank" href="https://www.linkedin.com/company/europebet/">
                                <svg id="Group_18" data-name="Group 18" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path id="Path_33" data-name="Path 33" d="M0,0H24V24H0Z" fill="none"/>
                                    <path id="Path_34" data-name="Path 34" d="M18.335,18.339H15.67V14.162c0-1-.02-2.278-1.39-2.278-1.389,0-1.6,1.084-1.6,2.2v4.25H10.013V9.75h2.56v1.17h.035a2.812,2.812,0,0,1,2.528-1.387c2.7,0,3.2,1.778,3.2,4.091v4.715ZM7,8.575A1.548,1.548,0,1,1,8.551,7.028,1.546,1.546,0,0,1,7,8.575Zm1.336,9.764H5.666V9.75H8.34v8.589ZM19.67,3H4.329A1.312,1.312,0,0,0,3,4.3V19.7A1.312,1.312,0,0,0,4.328,21H19.666A1.317,1.317,0,0,0,21,19.7V4.3A1.317,1.317,0,0,0,19.666,3h0Z" transform="translate(0 0)" fill="#fff"/>
                                </svg>
                            </a>
                        </div>
                        <p>
                          Copyright © 2010-2024 Europebet.com. <br/>  ყველა უფლება დაცულია.
                        </p>
                    </div>
                    <!-- <div class="column-item">
                        <h4>
                            პროდუქტები
                        </h4>
                        <ul>
                            <li>
                                <a href="#">
                                    ლაივი
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    სლოტები
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    პასუხისმგებლიანი თამაში
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                   ვერიფიკაცია
                                </a>
                            </li>
                             <li>
                                <a href="#">
                                   წესები და პირობები
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="column-item">
                        <h4>
                            ევროპაბეთი
                        </h4>
                        <ul>
                            <li>
                                <a href="#">
                                    კონტაქტი
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    ხშირად დასმული კითხვები
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    სალაროები
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                   ჩვენ შესახებ
                                </a>
                            </li>
                             <li>
                                <a href="#">
                                  პოკერი
                                </a>
                            </li>
                        </ul>
                    </div>
                      <div class="column-item">
                        <h4>
                            დახმარება
                        </h4>
                        <ul>
                            <li>
                                <a href="#">
                                    უსაფრთხოება
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    კონფიდენციალურობა
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    მაგიდის თამაშები
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                   ვირტუალური თამაშები
                                </a>
                            </li>
                             <li>
                                <a href="#">
                                  აქციები
                                </a>
                            </li>
                        </ul>
                    </div> -->

                </div>
        </div>
    </div>
</template>
<script>


 export default {
  components: {
   
  },
  created() {
 
  }, 
  data () {
    return {
    
         
    }
  },
  methods: {
   
  },
  computed:{
   
 },
 mounted(){

    

 }
};
 
</script>
<style lang="scss">
@font-face {
    font-family: 'helv-55';
    src: url('../../assets/fonts/helv-55.otf');
}
@font-face {
    font-family: 'helv-65';
    src: url('../../assets/fonts/helv-65.otf');
}
@font-face {
    font-family: 'helv-75';
    src: url('../../assets/fonts/helv-75.otf');
}
@font-face {
    font-family: 'industry-2018';
    src: url('../../assets/fonts/Industry2018-Ultra.ttf');
}
a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,output,p,pre,q,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:after,blockquote:before,q:after,q:before{content:'';content:none}table{border-collapse:collapse;border-spacing:0}
body {
     background: #060608 !important;
}
.container {
  margin: 0 auto;
  @media (min-width: 992px) {
    width: 960px;
  }
   @media (min-width: 1200px) {
    width: 1140px;
  }
  @media (min-width: 1400px) {
    width: 1360px;
  }
  
}
 
 .footer-wrapper {
    margin-top: 100px;
    width: 100%;
    border-top: 1px solid #252729;
    padding: 50px 16px;
      @media (max-width: 991px) {
        padding-top: 30px;
        padding-bottom: 20px;
        margin-top: 50px;
        }


    .footer-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
        @media (max-width: 991px) {
            flex-direction: column;
            padding: 0 15px;
            width: auto;
        }

        .column-item {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
        @media (max-width: 991px) {
            margin-bottom: 25px;
        }
            .socials {
                display: flex;

                a {
                    margin-right: 10px;
                    opacity: 0.4;
                    transition: all 0.3s;
                    &:hover {
                        opacity: 1;
                        transition: all 0.3s;
                    }
                }
            }
            p {
                font-size: 16px;
                color: #fff;
                font-family: 'helv-55';
                margin-top: 10px;
                line-height: 1.5;
                opacity: 0.65;
                @media only screen and (max-width: 600px) {
                    font-size: 13px;
        }
            }
            h4 {
                color: #fff;
                font-size: 18px;
                font-family: 'helv-65';
            }
            ul {
                list-style: none;
                margin-top: 35px;
                @media (max-width: 991px) {
                    margin-top: 15px;
                }
                li {
                    margin-bottom: 15px;
                    a {
                        opacity: 0.5;
                        font-family: 'helv-55';
                        text-decoration: none;
                        color: #fff;
                        font-size: 14px;
                        transition: all 0.3s;
                        &:hover {
                            opacity: 1;
                            transition: all 0.3s;
                        }
                    }
                }
            }
        }
         
    }
 }

</style>
