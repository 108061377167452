<template>
    <div class="header-wrapper">
        <div class="container">
                <div class="header-row">
                    <div class="lf-side">
                        <h1>
                            <a href="/">
                              <img src="images/logo.svg" />
                            </a>
                        </h1>
                    </div>
                    <div class="rg-side">
                        <ul :class="{active: mobileMenuActive, 'menu-list': true}">
                          <li>
                            <a href="/">მთავარი</a>
                          </li>
                          <li>
                            <router-link to="/about"  >ჩვენ შესახებ</router-link>
                          </li>
                          <li> 
                            <router-link to="/benefits"  >ბენეფიტები</router-link>
                          </li>
                          <li>
                            <router-link to="/levelup"  >Level up</router-link>
                          </li>
                          <li class="formobile">
                            <router-link to="/jobs"  >შემოგვიერთდი</router-link>
                          </li>
                  
                        </ul>
                        <div :class="{active: mobileMenuActive, 'burger-menu-btn': true}" @click="mobileMenuToggle">
                          <span class="menu-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                              <g id="Group_17421" data-name="Group 17421" transform="translate(-279 -26)">
                                <path id="Path_13030" data-name="Path 13030" d="M3,4H17V5.5H3ZM7.667,9.25H17v1.5H7.667ZM3,14.5H17V16H3Z" transform="translate(282 29)" fill="#fff"/>
                                <path id="Path_13040" data-name="Path 13040" d="M13,0a13,13,0,0,1,6.509,24.255A12.662,12.662,0,0,1,13,26,13,13,0,0,1,13,0Z" transform="translate(279 26)" fill="#fff" opacity="0.1"/>
                              </g>
                            </svg> 
                          </span>
                          <span class="close-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                              <g id="Group_17218" data-name="Group 17218" transform="translate(-281 -26)">
                                <path id="Path_13032" data-name="Path 13032" d="M12,10.586l4.95-4.95L18.364,7.05,13.414,12l4.95,4.95L16.95,18.364,12,13.414l-4.95,4.95L5.636,16.95,10.586,12,5.636,7.05,7.05,5.636Z" transform="translate(282 27)" fill="#fff"/>
                                <circle id="Ellipse_32" data-name="Ellipse 32" cx="13" cy="13" r="13" transform="translate(281 26)" fill="#fff" opacity="0.1"/>
                              </g>
                            </svg>
                          </span>
                        </div>
                       
                        <a class="join-btn">
                          <router-link to="/jobs"  >შემოგვიერთდი</router-link>  
                       
                        </a>
                    </div>
                </div>
            </div>
    </div>
</template>
<script>

  

 export default {
  components: {
   
  },
  created() {
 
  }, 
  data () {
    return {
      mobileMenuActive: false,
         
    }
  },
  methods: {
   mobileMenuToggle() {
    this.mobileMenuActive = !this.mobileMenuActive
   }
  },
  computed:{
   
 },
 mounted(){

    

 }
};
 
</script>
<style lang="scss">
@font-face {
    font-family: 'helv-55';
    src: url('../../assets/fonts/helv-55.otf');
}
@font-face {
    font-family: 'helv-65';
    src: url('../../assets/fonts/helv-65.otf');
}
@font-face {
    font-family: 'helv-75';
    src: url('../../assets/fonts/helv-75.otf');
}
@font-face {
    font-family: 'industry-2018';
    src: url('../../assets/fonts/Industry2018-Ultra.ttf');
}
a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,output,p,pre,q,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:after,blockquote:before,q:after,q:before{content:'';content:none}table{border-collapse:collapse;border-spacing:0}
body {
     background: #060608 !important;
}
.container {
  margin: 0 auto;
  @media (min-width: 992px) {
    width: 960px;
  }
   @media (min-width: 1200px) {
    width: 1140px;
  }
  @media (min-width: 1400px) {
    width: 1360px;
  }
  
}
.header-row {
    display: flex;
    justify-content: space-between;
    height: 100px;
    background: #101415;
    position: relative;

    @media (max-width: 991px) {
      padding: 0 15px;
      height: 75px
    }

    .lf-side {
      display: flex;
      align-items: center;
        h1 {
          margin: 0;
          a {
            text-decoration: none;
            img {

            }
          }
        }
    }
    .rg-side {
     display: flex;
     align-items: center;
      .formobile{
        display: none;
      }
      .menu-list {
        list-style: none;
        margin: 0;
        display: flex;
        align-items: center;

        @media (max-width: 991px) {
          position: absolute;
          top: 75px;
          left: 0;
          flex-direction: column;
          width: calc(100% - 40px);
          background: #252727;
          padding: 20px;
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
          text-align: left;
          align-items: flex-start;
          transition: all 0.3s;
          opacity: 0;
          visibility: hidden;
          transform: translateY(10px);
          z-index: 5;

          &.active {
            transition: all 0.3s;
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
          }
        }

        li {
          margin-right: 60px;
            @media (min-width: 992px) and (max-width: 1399px) {
              margin-right: 30px;
            }
            @media (max-width: 991px) {
              margin: 15px 0;
              margin-right: 0;
              &.formobile{
                display: inline-block;
              }
            }
          a {
            color: #fff;
            font-size: 16px;
            text-decoration: none;
            position: relative;
            transition: all 0.3s;
                font-family: 'helv-55';
            @media (min-width: 992px) and (max-width: 1399px) {
              font-size: 14px
            }
            &:hover {
                opacity: 0.8;
                transition: all 0.3s;
            }
            &.router-link-exact-active  {
              &::before {
                content: '';
                width: 5px;
                height: 5px;
                border-radius: 100%;
                background: #f05a22;
                display: inline-block;
                position: absolute;
                left: -8px;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
    }
    .join-btn {
        width: 200px;
        height: 50px;
        color: #fff;
        font-size: 16px;
        border-radius: 120px;
        background: #f05a22;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        font-family: 'helv-55';
        transition: all 0.3s;
        a{
          color:#fff;
          text-decoration: none;
        }
        svg {
          margin-left: 15px;
          width: 18px;
          height: 18px;
        }
            @media (min-width: 992px) and (max-width: 1399px) {
              width: 160px;
              height: 45px;
              font-size: 14px;
            }
            @media (max-width: 991px) {
              display: none;
            }
        &:hover {
          opacity: 0.8;
          transition: all 0.3s;
        }
    }
}
@media (min-width: 992px){
   
 .burger-menu-btn {
  display: none;
 }
}
.burger-menu-btn {
  .close-icon {
      display: none;
    }
  &.active {
    .menu-icon {
      display: none;
    }
    .close-icon {
      display: block;
    }
  }
}
.header-wrapper {
  border-bottom: 1px solid #252729;
  background: #101415;
}

</style>
