<template>
<div class="app-wrapper">
<my-header></my-header>

<div class="home-content-wrapper">
    <div class="banner-row-item">
    <iframe style="width: 100%;" height="500" src="https://www.youtube.com/embed/_kSzK9h5AEs?playlist=_kSzK9h5AEs&controls=0&autoplay=1&rel=0&modestbranding=1&mute=1&loop=1" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>    </div>
    <div class="levelup-wrapper">
        <div class="container">
            <div class="heading">
                   <h4> 
                        დაიწყე და განავითარე შენი კარიერა eteam-ში 
                    </h4>
                    <p>
                        Level up არის eteam-ის სტაჟირების პროგრამა, რომლის მონაწილეებსაც ეძლევათ პროფესიული უნარ-ჩვევების განვითარებისა და ახალი შესაძლებლობების აღმოჩენის საშუალება. პროგრამის ფარგლებში მენტორობას გაგიწევენ სხვადასხვა სფეროს ექსპერტები და დაგეხმარებიან პრაქტიკული ცოდნის მარტივად ათვისებაში. 
                        <br/>
                        <br/>
                        შენი მიზნები, ხედვები და ინტერესები აუცილებლად იპოვის ადგილს eteam-ში. შეუერთდი ჩვენს ენერგიულ და ინკლუზიურ გარემოს, გაეცანი ბიზნესს, დაამყარე მნიშვნელოვანი ადამიანური კავშირები და გაზარდე შენი კარიერული ხედვები ჩვენთან ერთად.
                    <br>
                    <br>
                    შეუერთდი ჩვენს 
                        <a href="https://www.betssongroup.com/career/available-jobs/" target="_blank" style="color: #F05A22;text-decoration: underline">Facebook</a> 
                      ჯგუფს და ადევნე თვალი Level Up-ის სიხალეებს.
                    </p>
            </div>
            <div class="timeline-wrapper">
                <div class="post-item">
                    <img src="/images/levelup/levelup1.png" />
                    <div class="details">
                        <h4>
                            Level up - 2020
                        </h4>
                        <p>
                            პროგრამაში ჩაერთო 12 სტუდენტი, რომლებმაც სტაჟირება გაიარეს ისეთი მოთხოვნადი
                             მიმართულებებით როგორიცაა ინფორმაციული ტექნოლოგიები, მარკეტინგი,
                             ხარისხის უზრუნველყოფა და ადამიანური რესურსები. 
                        </p>
                    </div>
                </div>
                   <div class="post-item">
                    <img src="/images/levelup/levelup2.png" />
                    <div class="details">
                        <h4>
                          Level up 2 - ტექნოლოგიები
                        </h4>
                        <p>
                            პროგრამაში ჩაერთო 14 სტუდენტი ტექნოლოგიების მიმართულებით. 
                            პროგრამა გაგრძელდა 6 თვე, რომლის ფარგლებშიც მონაწილეები 
                            ესწრებოდნენ სამუშაო შეხვედრებს, სემინარებს და მასტერკლასებს. 
                            პროგრამის განმავლობაში მონაწილეები იღებდნენ სტიპენდიას.
                        </p>
                    </div>
                </div>  
                   <div class="post-item" style="margin-top: 215px;">
                    <img src="/images/levelup/levelup3.png" />
                    <div class="details">
                        <h4>
                           Level up - ზაფხული
                        </h4>
                        <p>
                            ზაფხულის სტაჟირება ყველასთვის - Level up ეპატიჟება ნებისმიერ მსურველს 
                            იურიდიულ და ფინანსურ დეპარტამენტებში საზაფხულო სტაჟირების პროგრამაში 
                            მონაწილეობის მისაღებად. სტაჟირება გრძელდება 3-6 თვის განმავლობაში და 
                            მასში ჩართვა შეუძლია ყველას განურჩევლად ასაკისა და პროფესიული კვალიფიკაციისა. 
                            პროგრამის მონაწილეები მიიღებენ პრაქტიკულ გამოცდილებასა და ცოდნას სასურველი მიმართულებით, 
                            მონაწილეობას მიიღებენ კომპანიის ყოველდღიურ საქმიანობაში ,აიმაღლებენ კომპეტენციას 
                            და მიიღებენ კომპანიაში დასაქმებულების ბენეფიტებს.
                        </p>
                    </div>
                </div>    
            </div>
            <p class="levelup-footer">
                გაქვს კითხვები level up პროგრამასთან დაკავშირებით? შემოგვიერთდი <a href="https://www.facebook.com/groups/596725671301326/" target="_blank">Facebook ჯგუფში</a>.
            </p>
        </div>
    </div>  

</div>


<my-footer></my-footer>
</div>
</template>
<script>
import Header  from '../components/Header.vue';
import Footer  from '../components/Footer.vue';

export default {
  components: {
   'myHeader': Header,
   'myFooter': Footer,
  },
  created() {
    
  }, 
  data () {
    return {
       
         
    }
  },
  methods: {

  },
  computed:{
   
 },
 mounted(){

    

 }
};
 
</script>
<style lang="scss">
@font-face {
    font-family: 'helv-55';
    src: url('../../assets/fonts/helv-55.otf');
}
@font-face {
    font-family: 'helv-65';
    src: url('../../assets/fonts/helv-65.otf');
}
@font-face {
    font-family: 'helv-75';
    src: url('../../assets/fonts/helv-75.otf');
}
@font-face {
    font-family: 'industry-2018';
    src: url('../../assets/fonts/Industry2018-Ultra.ttf');
}
.levelup-footer{
    font-family: 'helv-55';
    text-align: center;
    color:#fff;
    margin-top: 50px;
    a{
        color:#F05A22;
    }
}


a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,output,p,pre,q,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:after,blockquote:before,q:after,q:before{content:'';content:none}table{border-collapse:collapse;border-spacing:0}
body {
     background: #060608 !important;
}
.container {
  margin: 0 auto;
  @media (min-width: 992px) {
    width: 960px;
  }
   @media (min-width: 1200px) {
    width: 1140px;
  }
  @media (min-width: 1400px) {
    width: 1360px;
  }
  
}
.banner-row-item {
    width: 100%;
    display: flex; 
    img {
      width: 100%;
    }
    iframe {
      
       @media(max-width: 767px) {
         height: 300px
       }
    }
}
.levelup-wrapper {
    background-image: url('/images/bgabout.png');
    background-position: 120px -400px;
    background-size: contain;
    background-color: #060608;
    background-repeat: no-repeat;
    @media (max-width: 991px) {
        background-image: none;
    }
    .heading {
        text-align: center;
        padding-top: 50px;
            @media (max-width: 757px) {
                text-align: left;
                padding-top: 25px;
            }
        h4 {
            font-size: 24px;
            color: #fff;
            font-family: 'helv-65';
            padding: 0 15px;
            line-height: 29px;
            max-width: 82%;
            margin: 0 auto 15px;
             @media (max-width: 757px) {
                font-size: 14px;
                font-family: 'industry-2018';
            }
        }
        p {
            font-size: 16px;
            color: #C9C5C5;
            font-family: 'helv-55';
            line-height: 1.5;
            padding: 0 15px;
             @media (max-width: 757px) {
               font-size: 12px;
            }
        }
    }
    .timeline-wrapper {
        margin-top: 85px;
        display: inline-block;
        width: 100%;
        position: relative;

        &::after {
            content: '';
            width: 1px;
            background: #252729;
            position: absolute;
            left: 50%;
            top: 20px;
            transform: translateX(-50%);
            height: calc(100% - 300px);
        }

        .post-item {
            width: calc(100% / 2 - 45px);
            float: left;
            position: relative;

            &:before {
                content: '';
                background-image: url('/images/circles.svg');
                width: 48px;
                height: 48px;
                background-repeat: no-repeat;
                background-position: center;
                display: inline-block;
                position: absolute;
                right: -69px;
                top: -13px;
                z-index: 2;
            }

            &:nth-child(2n) {
                float: right;
                margin-top: 420px;

                &:before {
                    left: -69px;
                    top: -23px;
                }
            }

            img {
                width: 100%;
            }
            .details {
                display: inline-block;
                width: 100%;
                margin-top: 40px;

                h4 {
                    font-size: 24px;
                    font-family: 'industry-2018';
                    color: #fff;
                }
                p {
                    font-size: 16px;
                    color: #A2A2A2;
                    font-family: 'helv-55';
                    line-height: 24px;
                    margin-top: 20px;
                }
            }
        }
    }
}
@media (max-width: 991px) {
    .levelup-wrapper .timeline-wrapper::after,
    .levelup-wrapper .timeline-wrapper .post-item:before {
        display: none
    }
    .levelup-wrapper .timeline-wrapper .post-item {
        float: none !important;
        margin-top: 30px !important;
        width: calc(100% - 30px);
        padding: 0 15px
    }
    .levelup-wrapper .heading h4 {
        max-width: 100%;
        padding: 0 15px;
        font-size: 14px;
        font-family: 'helv-55';
        line-height: 1.4;
    }
    .levelup-wrapper .heading {
        padding-top: 25px;
    }
    .levelup-wrapper .heading p {
        font-size: 14px;
    }
    .levelup-wrapper .timeline-wrapper {
        margin-top: 25px;
    }
    .levelup-wrapper .timeline-wrapper .post-item .details {
        margin-top: 20px;
    }
    .levelup-wrapper .timeline-wrapper .post-item .details h4 {
        font-size: 18px;
    }
    .levelup-wrapper .timeline-wrapper .post-item .details p {
        font-size: 13px;
        line-height: 1.3;
    }
}
 
 
</style>
