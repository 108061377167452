<template>
<div class="app-wrapper">
<my-header></my-header>

<div class="home-content-wrapper">
    <div class="banner-row-item has-bg about">
      
    </div>
    <div class="about-us-wrapper">
        <div class="container">
            <div class="about-us-title">
                <h4>
                    ჩვენ შესახებ
                </h4>
                <p>
                    ევროპაბეთი, რეგიონის ერთ-ერთი ლიდერი გემბლინგ კომპანიაა, რომელიც 2010 წლიდან სთავაზობს მოთამაშეებს ისეთ მრავალფეროვან ონლაინ სერვისებს, როგორიცაა ფსონები სპორტზე, ლაივ კაზინო, სლოტ თამაშები, კენო და რეგიონში ყველაზე პოპულარული მაგიდის თამაშები. 
<br>
2015 წლიდან საერთაშორისო ბრენდის Bettson Group-ის წევრია და ინდუსტრიაში ახალ ინოვაციურ იდეებსა და ტექნოლოგიებს ნერგავს.
                </p>
            </div>
            <div class="team-list">
                <div class="member">
                    <div class="avatar">
                        <img src="/images/team/maia.png" />
                    </div>
                    <div class="details">
                        <span>CEO</span>
                        <h5>
                            მაია კოპინაძე
                        </h5>
                        <p>
                            ჩვენთვის მთავარი ტალანტებია! ნიჭიერ ადამიანებთან ერთად ვქმნით თავისუფალ გარემოს და გუნდურ სამუშაო წრეს. აქ ყველას აქვს შანსი განვითარდეს, გაიზარდოს და საკუთარი თავის საუკეთესო ვერსია შექმნას. გვიხარია, რომ ვამსხვრევთ სტერეოტიპებს - ჩვენთან ნებისმიერ თანამშრომელს თავად შეუძლია ახალი იდეების ინიცირება, რის აღსრულებაშიც ვეხმარებით ჩვენ და არა პირიქით.
                        </p>
                    </div>
                </div>
                <div class="member">
                    <div class="avatar">
                        <img src="/images/team/giguli.png" />
                    </div>
                    <div class="details">
                        <span>ფინანსური დირექტორი</span>
                        <h5>
                            გიგული ნიჟარაძე
                        </h5>
                        <p>
                            ევროპაბეთი არის ის ადგილი, სადაც მე ვარ მე. აქ ყოველთვის თავისუფალი ხარ ურთიერთობებში, ქმედებებსა და მისწრაფებებში. შეუძლებელია არ შეგიყვარდეს ჩვენი კომპანია იმ მომენტიდან, როგორც კი კარს შემოაღებ. May the force be with us!
                        </p>
                    </div>
                </div>
                <div class="member">
                    <div class="avatar">
                        <img src="/images/team/nodar.png" />
                    </div>
                    <div class="details">
                        <span>კომერციული დირექტორი</span>
                        <h5>
                            ნოდარ დურგლიშვილი
                        </h5>
                        <p>
                           ევროპაბეთისთვის ძალიან მნიშვნელოვანია გუნდის წევრების მუდმივი ზრდა და განვითარება. აქ ხელს უწყობენ ადამიანებს მაქსიმალურად გამოიყენონ თავიანთი შესაძლებლობები, ამისთვის ბევრი რამ კეთდება. კომპანიაში ყოველთვის ისმება კითხვა, ემსახურება თუ არა ჩვენი გადაწყვეტილებები ამ მისიას თუ პირიქით - შეიძლება ხელის შემშლელი იყოს.
                        </p>
                    </div>
                </div>
                <div class="member">
                    <div class="avatar">
                        <img src="/images/team/giorgi.png" />
                    </div>
                    <div class="details">
                        <span>იურიდიული დეპარტამენტის ოპერაციების მართვის დეპარტამენტის დირექტორი</span>
                        <h5>
                            გიორგი ქათამაძე
                        </h5>
                        <p>
                           ევროპაბეთში მუშაობა ჩემთვის არის შანსი ვაკეთო საინტერესო საქმეები საუკეთესო გუნდთან ერთად.
                        </p>
                    </div>
                </div>
                <div class="member">
                    <div class="avatar">
                        <img src="/images/team/lasha.png" />
                    </div>
                    <div class="details">
                        <span>ოპერაციების მართვის დეპარტამენტის დირექტორი</span>
                        <h5>
                          ლაშა კუპრეიშვილი
                        </h5>
                        <p>
                           საუკეთესო ორგანიზაციული კულტურის გამტარებელი კომპანია საქართველოს ბაზარზე.
                        </p>
                    </div>
                </div>
                 <div class="member">
                    <div class="avatar">
                        <img src="/images/team/tamara.png" />
                    </div>
                    <div class="details">
                        <span>HR დირექტორი</span>
                        <h5>
                          თამარ ქლიბაძე
                        </h5>
                        <p>
                            ჩემთვის ევროპაბეთი არის ადგილი, სადაც ყოველდღე ვიზრდები პიროვნულად და ვვითარდები პროფესიულად. გარემო, რომელიც მავსებს, მთავაზობს გამოწვევებს, მაძლევს რეალიზაციის შანსს და შთამაგონებს. ეს არის ადგილი, სადაც ვქმნი დასაქმებულისა და დამსაქმებლის ურთიერთობის ახალ კულტურას.
                        </p>
                    </div>
                </div>
                <div class="member">
                    <div class="avatar">
                        <img src="/images/team/vasil.png" />
                    </div>
                    <div class="details">
                        <span>საინფორმაციო ტექნოლოგიების დირექტორი</span>
                        <h5>
                         ვასილ გრიგალაშვილი
                        </h5>
                        <p>
                           ევროპაბეთის მისია, მიზნები და კულტურა თანამშრომლებს აძლევს საკუთარი პოტენციალის სრულად რეალიზაციისა და მუდმივი განვითარების მიზეზს და შესაძლებლობას. ერთად ვიზრდებით!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<my-footer></my-footer>
</div>
</template>
<script>
import Header  from '../components/Header.vue';
import Footer  from '../components/Footer.vue';

export default {
  components: {
   'myHeader': Header,
   'myFooter': Footer,
  },
  created() {
    
  }, 
  data () {
    return {
       
         
    }
  },
  methods: {

  },
  computed:{
   
 },
 mounted(){

    

 }
};
 
</script>
<style lang="scss">
@font-face {
    font-family: 'helv-55';
    src: url('../../assets/fonts/helv-55.otf');
}
@font-face {
    font-family: 'helv-65';
    src: url('../../assets/fonts/helv-65.otf');
}
@font-face {
    font-family: 'helv-75';
    src: url('../../assets/fonts/helv-75.otf');
}
@font-face {
    font-family: 'industry-2018';
    src: url('../../assets/fonts/Industry2018-Ultra.ttf');
}
a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,output,p,pre,q,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:after,blockquote:before,q:after,q:before{content:'';content:none}table{border-collapse:collapse;border-spacing:0}
body {
     background: #060608 !important;
}
.container {
  margin: 0 auto;
  @media (min-width: 992px) {
    width: 960px;
  }
   @media (min-width: 1200px) {
    width: 1140px;
  }
  @media (min-width: 1400px) {
    width: 1360px;
  }
}
.banner-row-item {
    width: 100%;
    display: flex;

    img {
      width: 100%;
    }
}
.about-us-wrapper {
    background-image: url('/images/bgabout.png');
    background-position: 120px -400px;
    background-size: contain;
    background-color: #060608;
    background-repeat: no-repeat;
    @media (max-width: 991px) {
        background-image: none;
    }
    .about-us-title {
        text-align: center;
        padding-top: 50px;
            @media (max-width: 757px) {
                text-align: left;
                 padding-top: 25px;
            }
        h4 {
            font-size: 28px;
            color: #fff;
            font-family: 'helv-65';
            margin-bottom: 15px;
            padding: 0 15px;
             @media (max-width: 757px) {
                font-size: 14px;
                font-family: 'industry-2018';
            }
        }
        p {
            font-size: 18px;
            color: #C9C5C5;
            font-family: 'helv-55';
            line-height: 1.5;
            padding: 0 15px;
             @media (max-width: 757px) {
               font-size: 12px;
            }
        }
    }
    .team-list {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-top: 100px;
        @media (max-width: 991px) {
           margin-top: 50px;
        }
        .member {
            display: flex;
            width: 100%;
            align-items: center;
            @media (max-width: 991px) {
                flex-direction: column;
            }
            &:not(:last-child) {
                margin-bottom: 100px;
                 @media (max-width: 991px) {
                    margin-bottom: 40px;
                }
            }

            &:nth-child(2n) {
                .avatar {
                    order: 2;
                    margin-left: 35px;
                    @media (max-width: 991px) {
                        order: unset;
                    }
                }
            }

            .avatar {
                img {
                    width: 520px;
                    @media (max-width: 991px) {
                        width: 205px
                    }
                }
            }
            .details {
                margin-left: 35px;
                  @media (max-width: 991px) {
                        margin-left: 0;
                        padding: 0 15px;
                        text-align: center;
                        margin-top: 35px;
                    }

                span {
                    color: #fff;
                    font-family: 'helv-65';
                    font-size: 16px;
                    margin-bottom: 10px;
                    display: inline-block;
                    
                    @media (max-width: 991px) {
                        font-family: 'industry-2018';
                        font-size: 12px;
                        line-height: 1.4;
                        margin-bottom: 5px;
                    }
                }
                h5 {
                    color: #fff;
                    font-family: 'helv-65';
                    font-size: 22px;
                    margin-bottom: 15px;
                    
                    @media (max-width: 991px) {
                        font-family: 'industry-2018';
                        font-size: 16px;
                    }
                }
                p {
                    color: #A2A2A2;
                    font-size: 16px;
                    font-family: 'helv-55';
                    line-height: 1.5;
                    @media (max-width: 991px) {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
 
</style>
