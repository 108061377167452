<template>
  <div id="app"> 
   <router-view /> 
  </div>
</template>

<script>
 import router from "@/router";
export default {
  components: { },
  created() {
    this.handleEnvLog();
    const html = document.documentElement;
    html.setAttribute('lang', (router.app._route.query.lang??process.env.VUE_APP_DEFAULT_LANGUAGE_CODE));
  },
  methods: {
    handleEnvLog() {
      if (process.env.VUE_APP_ENV_LOG === "true") {
        console.log("============= Env =============");
        console.log(process.env);
      
      }
    },
  },
};
</script>


<style lang="scss">
 body{
   background-color: black;
   color:#A2A2A2;
   overflow:hidden;
   overflow-y: scroll;
  scroll-behavior: smooth;
  @-moz-document url-prefix() {
    scrollbar-width: thin;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #2c3234;
  }
  &::-webkit-scrollbar-track {
    background-color: #101014;
  }
  &::scrollbar-track {
    border-radius: 10px;
    background-color: #101014;
  }
  &::-webkit-scrollbar {
    width: 5px !important;
  }
 }
 .text-uppercase {
  text-transform: uppercase;
  -moz-font-feature-settings: 'case';
  -webkit-font-feature-settings: 'case';
  font-feature-settings: 'case' on; }
    </style>
