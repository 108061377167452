<template>
 
 <div class="app-wrapper benefitspage">
<my-header></my-header>

<div class="jobs-content">

 <p class="jobs-title text-uppercase"> იპოვე შენთვის <span>სასურველი</span>  სამსახური</p>
 <p class="jobs-desc">აღმოაჩინე შენი მომავალი eteam-ში, სადაც შენზე მორგებული გარემო  და უამრავი <a href="/benefits">ბენეფიტები</a> დაგხვდება </p>
 <div class="topemployer"><img src="../../assets/topemployer.png"/></div>
 <div class="job-names"> 
    <span>Backend Developer</span>
    <span>Frontend Developer</span>
    <span>DevOps Engineer</span>
    <span>Incident Engineer</span>
    <span>Product Owner</span>
    <span>Scrum Master</span>
    <span>NetOps Engineer</span>
    <span>IT Support</span>
    <span>QA Engineer</span>
    <span>UI/UX Designer</span>
    <span>Graphic Designer</span>
    <span>System Administrator</span>
    <span>Social Media Manager</span>
    <span>Content Creator</span>
    <span>Data Analyst</span>
    <span>Online Support Operator</span>
    <span>NetOps Engineer</span>
    <span>Legal Counsel</span>
    <span>Finance Analyst</span>
    <span>Database Administrator</span>
 </div>
  <div class="job-boxes">
      <div class="job-box">
        <div class="job-name"><span class="text-uppercase">QA Engineer</span>  <div class="location">Tbilisi</div> </div>
        <a href="https://www.betssongroup.com/job/qa-engineer/" target="_blank" class="learn-more"> <span>გაიგე მეტი </span></a>
      </div>
      <div class="job-box">
        <div class="job-name"><span class="text-uppercase">Network Engineer</span>  <div class="location">Tbilisi</div> </div>
        <a href="https://www.betssongroup.com/job/senior-network-engineer/" target="_blank" class="learn-more"> <span>გაიგე მეტი </span></a>
      </div>
      <div class="job-box">
        <div class="job-name"><span class="text-uppercase">Senior System Engineer</span>  <div class="location">Tbilisi</div> </div>
        <a href="https://www.betssongroup.com/job/senior-systems-engineer/" target="_blank" class="learn-more"> <span>გაიგე მეტი </span></a>
      </div> 
  
      <div class="job-box diff">
       <p class="c-title text-uppercase">europebet 2010 წლის ნოემბრიდან მოღვაწეობს ქართულ ბაზარზე და ერთ-ერთ წამყვან პოზიციას იკავებს ონლაინ სათამაშო ბიზნესის სფეროში.</p>
        <img src="../../assets/mob-divider.png"/>
       <p class="c-desc">2015 წლიდან კი საერთაშორისო ბრენდის - "Betsson Group"-ის წევრია. 
            ხარისხიანი მომსახურებისა და სერვისების ფართო არჩევანი კომპანიის ძირითადი მიზანი და ფასეულობაა. 
            კომფორტული და შემოქმედებითი სამუშაო გარემო კი თანამშრომლების ზრდისა და განვითარებისკენაა მიმართული. <br/>
            ამ ფასეულობების დამსახურებით "europebet" გამორჩეული დამსაქმებელია ლოკალურ ბაზარზე და ამას ის ფაქტიც ადასტურებს, 
            რომ 2024 წლის იანვარში კომპანიამ "Top Employer"-ის სტატუსი მიიღო, მსოფლიო ტექ ინდუსტრიის ჭრილში.</p>
        <a href="https://eb.ge/Vacancy-Betssongroup" target="_blank">Betsson Group</a>
      </div>
  </div>
  <img class="job-asset1" src="../../assets/main-asset.png"/>
  <img class="job-asset2" src="../../assets/main-asset.png"/>
</div>
<div class="bottom-carousel benefit">
        <carousel :autoplay="true"  :items="5" :margin="15" :autoplayTimeout="3000" :loop="true" :dots="false" :responsive="{0:{items:2,nav:false},600:{items:3,nav:false},992:{items:9,nav:false}}">
            <img src="/images/smslider/image1-min.jpg">
            <img src="/images/smslider/image2-min.jpg">
            <img src="/images/smslider/image3-min.jpg">
            <img src="/images/smslider/image4-min.jpg">
            <img src="/images/smslider/image5-min.jpg">
            <img src="/images/smslider/image6-min.jpg">
            <img src="/images/smslider/image9-min.jpg">
            <img src="/images/smslider/image10-min.jpg">
            <img src="/images/smslider/image11-min.jpg">
            <img src="/images/smslider/image12-min.jpg">
            <img src="/images/smslider/image13-min.jpg">
            <img src="/images/smslider/image15-min.jpg">
        
        </carousel>
   </div>

<my-footer></my-footer>
</div>
</template>
<script>
import Header  from '../components/Header.vue';
import Footer  from '../components/Footer.vue';

import carousel from 'vue-owl-carousel'


export default {
  components: {
   'myHeader': Header,
   'myFooter': Footer,
   carousel
  },
  created() {
    
  }, 
  data () {
    return {
       
         
    }
  },
  methods: {

  },
  computed:{
   
 },
 mounted(){
 }
};
 
</script>
<style lang="scss">
.topemployer{
  margin-top: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width:100%;
    max-width: 120px;
  }
}
.jobs-content{
  overflow: hidden; 
  color:#fff;
  font-family: 'helv-55';
  background-image: url("../../assets/bg.webp");
  background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
    background-color: #060608;
    .job-asset1{
      position:absolute;
      pointer-events: none;
      left:2%;
      top:100%;
      width:110px;  
    }
    .job-asset2{
      position:absolute;
      pointer-events: none;
      right:-70px;
      top:90%;
      width:110px;  
    }
    .jobs-desc{
      opacity: 0.8;
      line-height: 1.2;
    }
  
  .jobs-title,.jobs-desc{
    text-align: center;
    font-size: 20px;
    width:100%;
    max-width: 940px;
    margin:0 auto;
    font-family: 'helv-55';
    a{
      text-decoration: underline!important;
    }
     a,span{
      color:#E1602E;
      text-decoration: none;

    }
  }
  .jobs-title{
    margin-top: 80px;
    margin-bottom: 10px;
    font-size: 40px;
     font-family: 'helv-75';
  }
  .job-names{
    overflow: hidden;
    display:flex;
    justify-content: center;
    width:calc(100% + 100px);
    left:-50px;
    flex-wrap: wrap;
    margin-top: 34px;
    position:relative;
    font-family: 'helv-55';
    span{
      border:1px solid #707070;
      border-radius: 30px;
      padding:10px 14px;
      margin:0 5px 10px 5px;
      font-size: 20px;
      color:#E4E4E4;
      white-space: nowrap;
    }
  }
  .job-boxes{
    display: flex;
    flex-direction: column;
    max-width: 1150px;
    width:90%;
    margin:60px auto;
    z-index: 999;
    position:relative;
    .job-box{
      border-radius: 16px;
      background-color:rgba(39, 39, 42, 0.5);
      padding:40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      &.diff{
        font-family: 'helv-55';
        flex-direction: column; 
        align-items: flex-start;
        background-image: url("../../assets/job-asset.png");
        background-repeat: no-repeat;
        background-position: bottom 30px right 40px;
        background-size: 100px;
        padding-right: 15%;
        img{
          display: none;
        }
        a{
          color:#F55500; 
          margin-top: 12px;
        }
      }
      .c-title{
        font-size: 20px;
        margin-bottom: 12px;
        font-family: 'helv-75';
      }
      .c-desc{
        line-height: 1.2;
        font-size: 16px;
      }
      .job-name{
        display: flex;
        align-items: center;
        .location{
          opacity: 0.6;
          margin-left: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: 'helv-55';
          &:before{
            content:"";
            background-image: url("../../assets/location.svg");
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            width:20px;
            height: 23px;
            margin-right: 8px;
          }
        }
      }
      .learn-more{
        background-color: #F05A22;
        border-radius: 30px;
        font-size: 16px;
        padding:12px 20px;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        
        span{
          text-decoration: none;
          color:#fff;
        }
        &:after{
            content:"";
            background-image: url("../../assets/learn.svg");
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            width:23px;
            height: 24px;
            margin-left: 4px;
            display: inline-block;
          }
      }
    }
  }
 @media only screen and (max-width:1200px){
   .jobs-title{
    font-size: 44px;
   }
   .jobs-desc{
    font-size: 18px;
   }
  .job-names span{
    font-size: 20px;
  }
 }
 @media only screen and (max-width:768px){
  
     background-color: #111315; 
     .job-asset1,.job-asset2{
      display: none;
     }
     .job-names{
       margin-top: 16px;
     }
     .jobs-title{
      font-size: 16px;
      width:90%;
      margin-top: 30px;
      text-shadow: 0 0 10px black;
     }
     .jobs-desc{
      font-size: 12px;
      width:90%;
     }
    .job-boxes{
      margin:24px auto;
      .job-box{
        border-radius: 10px;
        background-color: #191C1F;
        padding:40px 16px 0 16px; 
        flex-direction: column;
        margin-bottom: 40px;
        .job-name{
          .text-uppercase{
            font-size: 14px;
          }
        }
        .learn-more{
          box-shadow: 0px 0px 0px 4px #111315;
          font-size: 13px;
          padding:8px 16px;
          &:after{
            width:18px;
            height:18px;
          }
        }
        &.diff{
          text-align: center;
          padding-bottom: 40px;
          align-items: center;
          margin-bottom: 16px;
          padding:40px 16px;
          background-image: none;
          .c-title{
            position:relative;
            z-index: 0;
            &:before{
              content:"";
              width:24px;
              height: 24px;
              background-image: url("../../assets/eb-asset.png");
              background-repeat: no-repeat;
              background-size: contain;
              position:absolute;
              bottom: -10px;
              right: -10px;
              z-index: -1;
            }
            &:after{
              content:"";
              width:24px;
              height: 24px;
              background-image: url("../../assets/white-star.png");
              background-repeat: no-repeat;
              background-size: contain;
              position:absolute;
              bottom: -30px;
              left: 0px;
              z-index: -1;
            }
          }
          a{
            display: none;
          }
          img{
            display: inline-block;
            margin-bottom: 12px;
          }
          .c-title,.c-desc{
            font-size: 13px;
            line-height: 1.2;
          }
        }
        .learn-more{ 
          margin-top: 16px;
          margin-bottom: -20px;
          border-radius: 8px;
        }
      }
    }
    .job-names { 
      span{
        font-size: 10px;
        margin:0 2px 10px 2px;
        padding:8px;
        &:nth-child(n+10){
         display: none;
        }
      }
    }
 }

}



.benefits-footer{
        box-shadow: 0px -50px 40px 0px #060608;
    text-align: center;
    color:#fff;
    font-size: 18px;
    padding:20px 0 80px 0;
    a{
        color:#F05A22;
    }
}
.main-title{
   text-align: center;
   color:#fff;
}
.benefits-container{
    font-family: 'helv-55'; 
    color:#A2A2A2;
    margin-top: 60px; 
    line-height: 1.3;
    background-image: url('/images/benefits-bg.png');
    background-repeat-y: repeat;
    background-position: top center;
    background-size: contain;

    .benefits-boxes{
        width:100%;
        margin:50px 0;
        display: inline-flex;
        justify-content: center;
        align-items: stretch;
       .benefits-box{
        .desc{
           font-size: 16px;
        } 
        font-size: 13px; 
        a{
          color:#F05A22;
        }
         
         width:50%;
        // border:1px solid green;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        .dotted-wrapper{
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            span{ 
                position: relative;
                display: inline-flex;
                align-items: center;
                padding-left: 10px;
                &:before{
                    left:0px;
                    content:"";
                    width:4px;
                    height: 4px;
                    background-color: #FF3A00;
                    border-radius: 50%;
                    position:absolute;
                }
            }
        }

        &.has-text{
             ul{
                    margin-left: 11%;
                    margin-right: 12%;
                    width:100%;
                li{
                    margin-bottom: 8px;
                    &:nth-child(odd){
                        margin-bottom: 4px;
                        color: #fff;
                    }
                }
            }
        }
       
        &.img-wrap{ 
            justify-content: flex-start;
            img{
            
                max-width: 89%;
                border-top-right-radius: 11px;
                border-bottom-right-radius: 11px;
            }
            &.right{
              justify-content: flex-end;
              img{
                border-radius: 0;
                 border-top-left-radius: 11px;
                border-bottom-left-radius: 11px;
              }
            }
        }
        &.first{
          
            ul{
                width: 85%;
                li{ 
                    margin-bottom: 8px;
                    &:first-child{
                        font-size: 16px;
                    }
                }
            }
            a{
                color:#F05A22;
                text-decoration: underline;
            }
        }
        &.last{
            p{
                margin-bottom:10px;
            }
          ul{
            margin-right: 12%;
          }
        }
       }
        
    }
 
    @media only screen and (max-width:1400px){
 .benefits-boxes .benefits-box.first ul li,.benefits-boxes .benefits-box.first ul li:first-child{
  font-size: 13px;
}
    }
    @media only screen and (max-width:1280px){
      font-size: 14px;
      .benefits-boxes{
        margin:30px 0;
        .benefits-box{
           align-items: flex-start;
          .last ul{
            margin-left: 8%;
            margin-right: 2%;
          }
          &.img-wrap{
            align-items: flex-start;
            img{
                max-width: 92%;
            } 
          }
          &.has-text ul {
              margin-left: 8%;
          }
        }
      }
    }
     @media only screen and (max-width:800px){
      
      .benefits-boxes{
        flex-direction: column;
        .benefits-box{
          width:100%;
          &.first{
            ul{
              width:calc(100% - 48px);
            }
          }
          &.img-wrap{
            margin-bottom: 24px;
            justify-content: center; 
                img{
                  max-width: calc(100% - 48px);
                  border-radius: 20px;
                } 
            &.right{
              justify-content: center;
                img{
                  max-width: calc(100% - 48px);
                  border-radius: 20px;
                }
            }
          
          }
          &.has-text{
            ul{
              margin-left: 24px;
            }
          }
        }
        &.reverse{
          flex-direction: column-reverse;
        }
      }
     }
}
@media only screen and (max-width:768px){
  .topemployer{
    margin-top: 12px;
    img{
      max-width: 80px;
    }
  }
}
@font-face {
    font-family: 'helv-55';
    src: url('../../assets/fonts/helv-55.otf');
}
@font-face {
    font-family: 'helv-65';
    src: url('../../assets/fonts/helv-65.otf');
}
@font-face {
    font-family: 'helv-75';
    src: url('../../assets/fonts/helv-75.otf');
}
@font-face {
    font-family: 'industry-2018';
    src: url('../../assets/fonts/Industry2018-Ultra.ttf');
}
.text-uppercase{
      font-family: 'helv-75';
      font-size: 18px;
}
a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,output,p,pre,q,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:after,blockquote:before,q:after,q:before{content:'';content:none}table{border-collapse:collapse;border-spacing:0}
body {
     background: #060608 !important;
}
.container {
  margin: 0 auto;
  @media (min-width: 992px) {
    width: 960px;
  }
   @media (min-width: 1200px) {
    width: 1140px;
  }
  @media (min-width: 1400px) {
    width: 1360px;
  }
}
.banner-row-item {
    width: 100%;
    display: flex;

    &.has-bg{
          padding-top: 26%; 
 
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    @media only screen and (max-width:768px){
  padding-top: 46%;
}
    }

    &.about{
         background-image: url('/images/aboutcover.png');
    }
    &.benefits{
         background-image: url('/images/benefits/benefitscover.png');
    }

   

}
.benefits-wrapper {
    background-image: url('/images/bgabout.png');
    background-position: 120px -400px;
    background-size: contain;
    background-color: #060608;
    background-repeat: no-repeat;
    @media (max-width: 991px) {
        background-image: none;
    }
    .heading {
        text-align: center;
        padding-top: 50px;
            @media (max-width: 757px) {
                text-align: left;
                padding-top: 25px;
            }
        h4 {
            font-size: 24px;
            color: #fff;
            font-family: 'helv-65';
            padding: 0 15px;
            line-height: 32px;
            max-width: 70%;
            margin: 0 auto 15px;
             @media (max-width: 757px) {
                font-size: 12px;
                font-family: 'helv-55';
                max-width: unset;
                line-height: 1.5;
            }
        }
    }
    .benefit-list {
        margin-top: 50px;
        .item {
            display: flex;
            align-items: center;
            margin-bottom: 100px;
            @media (max-width: 767px) {
                flex-direction: column;
                padding: 0 15px;
                margin-bottom: 50px;
            }
            img {
                width: 50%;
                border-radius: 20px;
                 @media (max-width: 767px) {
                   width: 100%;
                   order: unset !important
                }
            }
            &:nth-child(2n) {
                img {
                    order: 2
                }
                .details {
                    order: 1;
                }
            }
            .details {
                margin-left: 30px;
                width: 50%;
                padding-right: 50px;
                @media (max-width: 767px) {
                    width: 100%;
                    margin-top: 25px;
                    margin-left: 0;
                    padding-right: 0;
                }
                h4 {
                    font-size: 24px;
                    color: #fff;
                    font-family: 'industry-2018';
                     @media (max-width: 767px) {
                        font-size: 18px;
                    }
                }
                ul {
                    margin-top: 15px;
                    li {
                        color:#A2A2A2;
                        font-size: 14px;
                        font-family: 'helv-55';
                        position: relative;
                        margin-bottom: 10px;
                        line-height: 1.3;
                        padding-left: 5px;

                        @media (max-width: 767px) {
                            word-break: break-all;
                            padding-right: 15px;
                        }

                        &::before {
                            content: '';
                            width: 4px;
                            height: 4px;
                            background: #F05A22;
                            border-radius: 100%;
                            position: absolute;
                            left: -5px;
                            top: 7px;
                        }
                    }
                }
                p {
                    color:#A2A2A2;
                    font-size: 14px;
                    font-family: 'helv-55';
                    line-height: 1.3;
                    margin-top: 15px;

                    a {
                        color: #F05A22;
                        text-decoration: none;
                    }
                }
            }
        }
    }
  
}
  .bottom-carousel {
        background: #19191A;
        padding-top: 100px;
        padding-bottom: 100px;
        &.benefit{
          background: rgba(255,255,255,0.04);
          padding:50px 0;
        }
        @media (max-width: 991px) {
            padding-top: 50px;
            padding-bottom: 50px;  
        }
        .owl-item img {
            border-radius: 8px;
        }
    }
.benefitspage .footer-wrapper {
    margin-top: 0
}
 
</style>
