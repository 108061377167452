import Vue from "vue";
import App from "./App.vue";
import Axios from "axios";
import router from "./router"; 
import AppInitializerService from "./core/services/app-initializer.service";
import moment from 'moment'
import BootstrapVue from 'bootstrap-vue' 
import 'bootstrap-vue/dist/bootstrap-vue.min.css'
import VueCountdownTimer from 'vuejs-countdown-timer';
import LoadScript from 'vue-plugin-load-script';
import VueYouTubeEmbed from 'vue-youtube-embed';
Vue.config.productionTip = false;

Vue.prototype.$http = Axios;
Vue.prototype.moment = moment;
Vue.use(BootstrapVue);
Vue.use(VueCountdownTimer);
Vue.use(LoadScript);
Vue.use(VueYouTubeEmbed)

AppInitializerService.init(false).then(() => {
  new Vue({
    router, 
    async created() {
      await AppInitializerService.init(true);
      this.$mount("#app");
    },
    render: (h) => h(App),
  });
});
