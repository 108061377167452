<template>
  <div class="app-wrapper">
    <my-header></my-header>

    <div class="home-content-wrapper main-page">
      <div class="banner-row-item">
        <div class="container">
 
          <a href="https://www.marketer.ge/saintereso-podkasti-sauketeso-damsakmebeli/?fbclid=IwAR38Ari_aEv6QZtaKxei2NFwJZO53yqBRzaX3zIXjbf1wDVvKz1R-VI6cl0%2CIwAR1q8Zamqlj-BnPa146jlV4IiuMgC12DpFMyicgdMlt2nExIUQKXoOFeoWo" target="_blank" class="banner-inner">
            <div class="lf-side">
              <div class="logos">
                <img src="/images/main-slider/logo.png" />
              
              </div>
        
              <h4>
                e team ტოპ <br/> დამსაქმებელი <br/>საქართველოში
              </h4>

          
              <a>
                პოდკასტი
              </a>
            </div>
            <div class="rg-side">
              <img src="/images/main-slider/hr.png" />
            </div>
          </a>
        </div>
      </div>
      <div class="carousels-wrapper">
        <div class="container has-bg">
          <div class="carousels-box">

            <div class="carousels">
              <div class="top-carousel">
                <div class="carousel-heading">
                  <div class="lf-side">
                    <h4>
                      გვემეგობრე ინსტაგრამზე
                    </h4>

                  </div>
                </div>
                 <div class="elfsight-app-d4adecc3-75e3-4968-a072-4a67f078ff6c" data-elfsight-app-lazy></div>
               
              </div>
              <div class="top-carousel" style="margin-top: 40px">
                <div class="carousel-heading">
                  <div class="lf-side">
                    <h4>
                      გვემეგობრე ლინკდინზე
                    </h4>

                  </div>
                </div>
                <div class="linkedin-car">
                  <carousel :items="5" :margin="5" :loop="false" :dots="false" :responsive="{0:{items:2,nav:true},600:{items:5,nav:false}}">
                     <a href="https://www.linkedin.com/feed/update/urn:li:activity:7202288518302167041" target="_blank">
                      <img src="/images/linkdin/p1.webp">
                    </a>
                    <a href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7204817892478038017/?actorCompanyId=2474765" target="_blank">
                      <img src="/images/linkdin/p2.webp">
                    </a>
                    <a href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7202288517492723713/?actorCompanyId=2474765" target="_blank">
                      <img src="/images/linkdin/p3.webp">
                    </a>
                     <a href="https://www.linkedin.com/feed/update/urn:li:activity:7189157076323942400" target="_blank">
                     <img src="/images/linkdin/p4.webp">
                    </a>
                    <a href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7204523225140125696/?actorCompanyId=2474765" target="_blank">
                     <img src="/images/linkdin/p5.webp">
                    </a>
                  </carousel>
                </div>
              </div>
            </div>
          </div>
          <div class="join-us">
            <div class="heading-row">
              <h2>
                გაეცანი e team-ის სტატიებს
              </h2>

            </div>
            <div class="gallery-images">
              <a href="https://soundcloud.com/marketerge/guka-mazanashvili" target="_blank" class="user-item" style="background-image: url('/images/hteam/post1.webp')">
                <div class="user-info">
                  <h5>
                    გუკა მაზანაშვილი 
                  </h5>
                  <p> ლიდერობა ტექ ინდუსტრიაში by Marketer.ge</p>
                </div>
              </a>
              <a href="https://eb.ge/gogachkhaidze" target="_blank" class="user-item" style="background-image: url('/images/hteam/post2.webp')">
                <div class="user-info">
                  <h5>
                    გოგა ჩხაიძე 
                  </h5>
                  <p> პროფესია, რომელიც ბავშვობიდან მიყვარს</p>
                </div>
              </a>
              <a href="https://eb.ge/inaghonghadze" target="_blank" class="user-item" style="background-image: url('/images/hteam/post3.webp'); background-position: top center">
                <div class="user-info">
                  <h5>
                   ინა ღონღაძე 
                  </h5>
                  <p> ბიზნეს გადაწყვეტილებები ციფრებსა და მონაცემებზე დაყრდნობით</p>
                </div>
              </a>
              <a href="https://eb.ge/IWvhdW" target="_blank" class="user-item" style="background-image: url('/images/hteam/post4.webp')">
                <div class="user-info">
                  <h5>
                    ნიკო ღორთლიშვილი 
                  </h5>
                  <p>გრაფიკული დიზაინიდან UI/UX დიზაინამდე</p>
                </div>
              </a>

              <a href="https://eb.ge/ninapirtskhalava"
                 target="_blank" class="user-item" style="background-image: url('/images/hteam/post5.webp')">
                <div class="user-info">
                  <h5>
                    ნინა ფირცხალავა 
                  </h5>
                  <p>ტექნოლოგიები, მენეჯმენტი, მარკეტინგი - ერთად</p>
                </div>
              </a>

            </div>
          </div>
        </div>
      </div>
    </div>

    <my-footer></my-footer>
  </div>
</template>


<!-- <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></script> -->
<script>

import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

import carousel from "vue-owl-carousel";

export default {
  components: {
    carousel,
    myHeader: Header,
    myFooter: Footer,
  },
  created() {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
  mounted() {},
};
</script>

<style lang="scss">
.insta-frame{
  width:100%;
}
.width-limited {
  max-width: 1128px;

  @media only screen and (min-width: 1400px) {
    transform: scale(1.1);
    transform-origin: left;
  }
}
@font-face {
  font-family: "helv-55";
  src: url("../../assets/fonts/helv-55.otf");
}
@font-face {
  font-family: "helv-65";
  src: url("../../assets/fonts/helv-65.otf");
}
@font-face {
  font-family: "helv-75";
  src: url("../../assets/fonts/helv-75.otf");
}
@font-face {
  font-family: "industry-2018";
  src: url("../../assets/fonts/Industry2018-Ultra.ttf");
}
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  background: #060608 !important;
}
.container {
  margin: 0 auto;
  @media (min-width: 992px) {
    width: 960px;
  }
  @media (min-width: 1200px) {
    width: 1140px;
  }
  @media (min-width: 1400px) {
    width: 1360px;
  }
}
.banner-row-item {
  width: 100%;
  display: flex;
  // @media (max-width: 991px) {
  //   background-image: url('/images/bgmob.png');
  //   background-position: center;
  //   background-size: 900px;
  //   background-repeat: no-repeat;
  // }
  .banner-inner {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 500px;
    text-decoration: none !important;
    @media (max-width: 1400px) {
      height: auto;
    }
    @media (max-width: 991px) {
      padding: 30px 15px 0 15px;
      justify-content: space-between;
      width: calc(100% - 30px);
    }
    @media (min-width: 1600px) {
      height: 540px;
    }
    .lf-side {
      @media (max-width: 991px) {
        width: auto;
      }
      .logos {
        display: flex;
        align-items: center;
        @media (max-width: 991px) {
          img {
            width: auto !important;
            margin-left: 0 !important;
            &:first-child {
              max-width: 180px;
              margin-right: 16px;
            }
            // &:last-child {
            //   max-width: 110px;
            // }
          }
        }
        img {
          width:290px;
          // &:last-child {
          //   width: 120px;
          //   margin-left: 30px;
          // }
        }
      }

      h4 {
        font-size: 40px;
        font-family: "helv-65";
        color: #fff;
        margin-top: 30px;
        margin-bottom: 30px;
        line-height: normal;
        text-decoration: none;
        @media (max-width: 1400px) {
          font-size: 34px;
        }
        @media (max-width: 991px) {
          font-size: 15px;
          margin-top: 15px;
          margin-bottom: 15px;
          br {
            display: none;
          }
        }
        @media (min-width: 992px) and (max-width: 1199px) {
          font-size: 30px;
        }
      }
      p {
        color: #c6c2c2;
        font-size: 24px;
        font-family: "helv-55";
        margin-bottom: 35px;
        margin-top: 25px;
        line-height: normal;
        @media (max-width: 991px) {
          font-size: 13px;
        }
      }
      a {
        height: 50px;
        // width: 190px;
        width: 220px;
        background: #f05a22;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-family: "helv-55";
        text-decoration: none !important;
        color: #fff;
        border-radius: 15px;
        transition: all 0.3s;

        &:hover {
          opacity: 0.8;
          transition: all 0.3s;
        }
        @media (max-width: 991px) {
          height: 40px;
          // width: 160px;
          width: 190px;
          font-size: 16px;
          border-radius: 10px;
        }
      }
    }
    .rg-side {
      height: 100%;
      align-self: flex-end;
      display: flex;
      align-items: flex-end;
      @media (max-width: 991px) {
        width: 400px;
        justify-content: flex-end;
        padding-left: 2vw;
      }
      img {
        max-width: 100%;
        width: 100%;
        height: auto;
        display: block;
        object-fit: contain;
        // @media (min-width: 992px) {
        //   width: 450px;
        // }
      }
    }
  }
}
.linkedin-car {
  @media (min-width: 1130px) {
    max-width: 1240px;
    margin: 0 auto;
  }
  img {
    border-radius: 6px;
  }
}
.main-page {
  background-image: url("/images/mainbg2.png");
  background-size: 2400px;
  background-position: center 0;
  background-repeat: no-repeat;
  @media (max-width: 991px) {
    background-image: none;
  }

  @media (min-width: 1600px) {
    background-size: 2600px;
  }
}
.carousels-box {
  p {
    font-family: "helv-75";
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    max-width: 72%;
    display: table;
    margin: 0 auto;
    padding-top: 60px;

    @media (max-width: 991px) {
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
      line-height: 1.4;
      max-width: 100%;
      font-size: 12px;
      font-family: "helv-55";
    }
  }
  .carousels {
    background: #0f0f15;
    padding: 60px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-top: 0;

    @media (max-width: 991px) {
      padding: 15px;
      margin-top: 0;
      width: calc(100% - 60px);
      margin-left: 15px;
    }

    .top-carousel {
      .carousel-heading {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 25px;

        .lf-side {
          display: flex;
          flex-direction: column;
          h4 {
            color: #fff;
            font-family: "helv-65";
            font-size: 24px;
            margin-bottom: 25px;
            @media (max-width: 991px) {
              font-size: 13px;
              margin-bottom: 0;
            }
          }
          p {
            padding: 0;
            margin: 0;
            text-align: left;
            font-size: 15px;
            line-height: normal;
            font-family: "helv-55";
            color: #fff;
            opacity: 0.4;
            margin-top: 15px;
            @media (max-width: 991px) {
              margin-top: 5px;
            }
          }
        }
      }
      &:last-child {
        .owl-item {
          a::after {
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj4KICA8cGF0aCBpZD0iUGF0aF8zNyIgZGF0YS1uYW1lPSJQYXRoIDM3IiBkPSJNMzAuMjYyLDMwLjI2OUgyNS41MjRWMjIuODQ0YzAtMS43NzEtLjAzNi00LjA1LTIuNDcxLTQuMDUtMi40NjksMC0yLjg0NiwxLjkyNy0yLjg0NiwzLjkydjcuNTU2aC00Ljc0VjE1aDQuNTUxdjIuMDhoLjA2MmE1LDUsMCwwLDEsNC40OTQtMi40NjZjNC44LDAsNS42ODksMy4xNjEsNS42ODksNy4yNzN2OC4zODJaTTEwLjExNiwxMi45MTFhMi43NTIsMi43NTIsMCwxLDEsMi43NTItMi43NSwyLjc0OCwyLjc0OCwwLDAsMS0yLjc1MiwyLjc1Wm0yLjM3NSwxNy4zNThINy43NFYxNWg0Ljc1NFYzMC4yNjlaTTMyLjYzNiwzSDUuMzYzQTIuMzMyLDIuMzMyLDAsMCwwLDMsNS4zMDZWMzIuNjk0QTIuMzMyLDIuMzMyLDAsMCwwLDUuMzYxLDM1SDMyLjYyOEEyLjM0MiwyLjM0MiwwLDAsMCwzNSwzMi42OTRWNS4zMDZBMi4zNDIsMi4zNDIsMCwwLDAsMzIuNjI4LDNoLjAwNVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zIC0zKSIgZmlsbD0iI2ZmZiIvPgo8L3N2Zz4K);
          }
        }
      }
    }
    .owl-nav {
      position: absolute;
      right: 0;
      top: -95px;

      @media (max-width: 991px) {
        top: -63px;
      }

      .owl-prev,
      .owl-next {
        color: transparent;
        width: 36px !important;
        height: 36px !important;
        border-radius: 100%;
        border: 1px solid #373839;
        background: transparent;
        max-width: 38px !important;
        font-size: 0;
        padding: 0;
        opacity: 0.5;
        transition: all 0.3s;

        @media (max-width: 991px) {
          width: 20px !important;
          height: 20px !important;
        }

        &:hover {
          opacity: 1;
          transition: all 0.3s;
          background-color: #373839;
        }
      }

      .owl-next {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3Ljc3OCIgaGVpZ2h0PSIxMi43MjgiIHZpZXdCb3g9IjAgMCA3Ljc3OCAxMi43MjgiPgogIDxwYXRoIGlkPSJQYXRoXzEzMDMzIiBkYXRhLW5hbWU9IlBhdGggMTMwMzMiIGQ9Ik0xMy4xNzIsMTIsOC4yMjIsNy4wNSw5LjYzNiw1LjYzNiwxNiwxMiw5LjYzNiwxOC4zNjQsOC4yMjIsMTYuOTVaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtOC4yMjIgLTUuNjM2KSIgZmlsbD0iI2ZmZiIvPgo8L3N2Zz4K) !important;
        background-repeat: no-repeat !important;
        background-position: 15px center !important;
        background-size: 10px !important;
        @media (max-width: 991px) {
          background-size: 5px !important;
          background-position: center !important;
        }
      }
      .owl-prev {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3Ljc3OCIgaGVpZ2h0PSIxMi43MjgiIHZpZXdCb3g9IjAgMCA3Ljc3OCAxMi43MjgiPgogIDxwYXRoIGlkPSJQYXRoXzEzMDMzIiBkYXRhLW5hbWU9IlBhdGggMTMwMzMiIGQ9Ik0xMy4xNzIsMTIsOC4yMjIsNy4wNSw5LjYzNiw1LjYzNiwxNiwxMiw5LjYzNiwxOC4zNjQsOC4yMjIsMTYuOTVaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtOC4yMjIgLTUuNjM2KSIgZmlsbD0iI2ZmZiIvPgo8L3N2Zz4K) !important;
        background-repeat: no-repeat !important;
        background-position: 15px center !important;
        background-size: 10px !important;
        transform: rotate(-180deg);
        @media (max-width: 991px) {
          background-size: 5px !important;
          background-position: center !important;
        }
      }
    }
  }
  .owl-item {
    position: relative;

    a {
      position: relative;
      display: inline-block;
      border-radius: 6px;
      overflow: hidden;
      img {
        transition: all 0.5s;
      }
      &:hover {
        img {
          transform: scale(1.1);
          transition: all 0.5s;
        }
        &::after {
          opacity: 1;
          transition: all 0.3s;
        }
        &::before {
          opacity: 0.5;
          transition: all 0.3s;
        }
      }
    }

    a::before {
      content: "";
      background: #000;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all 0.3s;
    }
    a::after {
      content: "";
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj4KICA8cGF0aCBpZD0iUGF0aF8xMzAzNyIgZGF0YS1uYW1lPSJQYXRoIDEzMDM3IiBkPSJNMTgsMmM0LjM0NywwLDQuODkuMDE2LDYuNi4xYTExLjc4NywxMS43ODcsMCwwLDEsMy44ODUuNzQ0LDcuOCw3LjgsMCwwLDEsMi44MzUsMS44NDVBNy44NTMsNy44NTMsMCwwLDEsMzMuMTYsNy41MiwxMS44MiwxMS44MiwwLDAsMSwzMy45LDExLjRjLjA3NSwxLjcwNi4xLDIuMjQ4LjEsNi42cy0uMDE2LDQuODktLjEsNi42YTExLjgyOSwxMS44MjksMCwwLDEtLjc0NCwzLjg4NSw4LjE2OSw4LjE2OSwwLDAsMS00LjY4LDQuNjhBMTEuODIsMTEuODIsMCwwLDEsMjQuNiwzMy45Yy0xLjcwNi4wNzUtMi4yNDguMS02LjYuMXMtNC44OS0uMDE2LTYuNi0uMUExMS44MjksMTEuODI5LDAsMCwxLDcuNTIsMzMuMTZhOC4xNjksOC4xNjksMCwwLDEtNC42OC00LjY4QTExLjc3OCwxMS43NzgsMCwwLDEsMi4xLDI0LjZDMi4wMjEsMjIuODksMiwyMi4zNDcsMiwxOHMuMDE2LTQuODkuMS02LjZBMTEuNzc4LDExLjc3OCwwLDAsMSwyLjg0LDcuNTIsNy44MDgsNy44MDgsMCwwLDEsNC42ODUsNC42ODUsNy44MzUsNy44MzUsMCwwLDEsNy41MiwyLjg0LDExLjc3OCwxMS43NzgsMCwwLDEsMTEuNCwyLjFDMTMuMTEsMi4wMjEsMTMuNjUzLDIsMTgsMlptMCw4YTgsOCwwLDEsMCw4LDhBOCw4LDAsMCwwLDE4LDEwWm0xMC40LS40YTIsMiwwLDEsMC0yLDJBMiwyLDAsMCwwLDI4LjQsOS42Wk0xOCwxMy4yQTQuOCw0LjgsMCwxLDEsMTMuMiwxOCw0LjgsNC44LDAsMCwxLDE4LDEzLjJaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMiAtMikiIGZpbGw9IiNmZmYiLz4KPC9zdmc+Cg==);
      width: 32px;
      height: 32px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: all 0.3s;
    }
  }
}

.join-us {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-bottom: 70px;
  padding: 20px 48px 48px 48px;
  background: #0f0f15;

  @media (max-width: 991px) {
    width: calc(100% - 30px);
    margin: 0 auto;
    padding: 0;
    padding-block: 10px;
  }
  .heading-row {
    display: inline-block;
    width: 100%;
    text-align: center;
    h2 {
      font-size: 28px;
      color: #fff;
      font-family: "helv-65";

      @media (max-width: 767px) {
        font-size: 14px;
        padding-left: 15px;
        text-align: left;
      }
    }
    p {
      font-size: 18px;
      color: #fff;
      opacity: 0.7;
      font-family: "helv-55";
      margin-top: 15px;
      @media (max-width: 767px) {
        font-size: 13px;
        padding-left: 15px;
        text-align: left;
        margin-top: 5px;
      }
    }
  }
  .gallery-images {
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    @media (max-width: 767px) {
      margin-top: 15px;
    }
    @media (max-width: 991px) {
      flex-wrap: unset;
      overflow-x: auto;
      width: calc(100% - 30px);
      margin-left: 15px;
      margin-block: 20px;
    }
    .user-item {
      position: relative;
      transition: all 0.3s;
      &:hover {
        opacity: 0.8;
        transition: all 0.3s;
      }
      @media (max-width: 991px) {
        min-width: 300px;
        height: 300px !important;
        margin-right: 15px;
        border-radius: 8px;
      }
      @media (max-width: 767px) {
        width: 110px !important;
        height: 140px !important;
        min-width: 110px !important;
      }

      &:first-child {
        width: calc(100% / 3);
        height: 495px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
      &:nth-child(2) {
        width: calc(100% / 3);
        height: 495px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
      &:nth-child(3) {
        width: calc(100% / 3);
        height: 495px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
      &:nth-child(4) {
        width: calc(100% / 2);
        height: 495px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
      &:nth-child(5) {
        width: calc(100% / 2);
        height: 495px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .user-info {
        position: absolute;
        left: 35px;
        bottom: 35px;
        min-height: 70px;
        @media (max-width: 767px) {
          left: 5px;
          bottom: 5px;
        }
        p {
          font-size: 14px;
          font-family: "helv-65";
          color: #fff;
          line-height: normal;

          @media (max-width: 767px) {
            font-size: 7px;
            margin-bottom: 3px;
          }
        }
        h5 {
          font-size: 18px;
          font-family: "helv-65";
          color: #fff;
          margin-bottom: 10px;
          @media (max-width: 767px) {
            font-size: 10px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .banner-row-item .banner-inner {
    width: 100%;
    flex-direction: column;
    height: auto;
    padding: 0;
    .lf-side {
      padding-top: 20px;
      box-shadow: 0px 2px 15px 2px #060608;
      padding-bottom: 16px;
      z-index: 1;
      .logos {
        justify-content: center;
        align-items: center;
        flex-direction: row;
        img {
          &:first-child {
           max-width: 120px;
          }
          // &:last-child {
          //   margin-top: 0;
          //   max-width: 100px;
          // }
        }
      }
      h4,
      a {
        display: none;
      }
    }
    .rg-side {
      width: 100%;
      max-width: 500px;
      padding-top: 42%;
      padding-left: 0;
      justify-content: center;
      background-position: bottom center;
      background-size: contain;
      background-repeat: no-repeat;
      // background-image: url("/images/dima.png");
      background-image: url("/images/main-slider/hr.png");
      img {
        display: none;
        max-width: 90%;
      }
    }
  }
  .banner-row-item{
        .container{
          width:100%;
          .rg-side{
            width:90%;
            align-self: center;
          }
        }
      }
}
</style>
