<template>
<div class="app-wrapper benefitspage">
<my-header></my-header>

<div class="home-content-wrapper">
    <div class="banner-row-item has-bg benefits">
      
    </div>

    <div class="benefits-container">
                <p class="text-uppercase main-title">
                            ბენეფიტები
                        </p>
        <div class="benefits-boxes reverse">
            <div class="benefits-box first">
                <ul  class="desc">
                <li>
                    ექსტრაორდინალური პროდუქტის შექმნას, ექსტრაორდინალური გარემო სჭირდება. 
                    ჩვენი ამოცანაა დავრწმუნდეთ, რომ შენ გაქვს ყველაფერი, რაც აუცილებელია მნიშვნელოვანი და 
                    რელევანტური პროდუქტის შექმნისათვის. ჩვენ მუდმივად ვზრუნავთ შენი თითოეული მოთხოვნის 
                    გადაჭარბებით დაკმაყოფილებაზე, რათა მაქსიმალურად კონცენტრირდე მომხმარებლებისათვის 
                    მომაჯადოვებელი გამოცდილების შექმნაზე.
                </li>
                <li  >
                    ევროპაბეთში გვჯერა, რომ კომპანიის წარმატების უმთავრესი მამოძრავებელი ძალა ხარ შენ - მოტივირებული და მიზანდასახული. 
                </li>
                <li  >
                    სწორედ ამიტომ, ჩვენ მუდმივად ვცდილობთ შენთვის და შენი ოჯახის წევრებისათვის საერთაშორისო 
                    ხარისხის გამოცდილებით და ბენეფიტებით უზრუნველყოფას, რომელთა უმთავრესი დანიშნულებაც ფიზიკური, 
                    ფინანსური და ემოციური კეთილდღეობის შექმნაა.
                </li>
                <li >
                    აღმოაჩინე შენი მომავალი eteam-ში. ეწვიე ჩვენს <a href="/jobs" >დასაქმების გვერდს.</a>
                </li>
                </ul>
            </div>
            <div class="benefits-box img-wrap right">
                <img src="/images/benefit/image1.jpg"/>
            </div>
        </div>
        <div class="benefits-boxes">
          
            <div class="benefits-box img-wrap ">
               <img src="/images/benefit/image2.jpg"/>
            </div>
            <div class="benefits-box right-text has-text">
                <ul>
                <li class="text-uppercase eb-font-bold">
                    მოქნილი გრაფიკი და დასვენება
                </li>
                <li class="desc">
                  მოქნილი გრაფიკი, რომელიც გეხმარება კომფორტულად მუშაობაში და დრო, რომელიც გჭირდება დასვენებისა და დამუხტვისათვის.
                </li>
                <li>
                  1. ჰიბრიდული მოდელი  
                </li>
                <li>
                  სამშაბათს და ხუთშაბათს შეგიძლია იმუშაო დისტანციურად.
                </li>
                <li>
                  2. მოქნილი გრაფიკი 
                </li>
                <li>
                  სტანდარტული გრაფიკით მუშაობის შემთხვევაში, თავად საზღვრავ იმუშაო 09:00 - 18:00, თუ 10:00 - 19:00 საათებში.
                </li>
                <li>
                 3.	მოკლე დღე
                </li>
                <li>
                  პარასკევს მუშაობ მხოლოდ 6 საათი.
                </li>
                <li>
                  4. შვებულების თავისუფალი დაგეგმვა
                </li>
                <li>
                   დამოუკიდებლად იცავ სამუშაოსა და პირადი ცხოვრების ბალანსს.
                </li>
                <li>
                  5. არა ზეგანაკვეთს
                </li>
                <li>
                  არ მუშაობ ზეგანაკვეთურად. ხოლო აუცილებლობის შემთხვევაში შენი დრო ანაზღაურდება.
                </li>


                </ul>
            </div>
        </div>
        <div class="benefits-boxes reverse">
             <div class="benefits-box right-text has-text">
                <ul>
                <li class="text-uppercase">
                   ფინანსური კეთილდღეობა
                </li>
                <li class="desc">
                კომპენსაციები და პროგრამები, რომლებიც შენთვის ფინანსური სიმშვიდის უზრუნველყოფაზეა ორიენტირებული.
                </li>
                <li>
                  1. ორჯერადი ანაზღაურება
                </li>
                <li>
                   ხელფასი გერიცხება თვეში ორჯერ: 50-50%. 
                </li>
                <li>
                  2. საბონუსე სისტემა
                </li>
                <li>
                   სარგებლობ კვარტალური საბონუსე სისტემით, რომლის მოქმედებაც დამოკიდებულია ბიზნესის მიერ კვარტალური გეგმის შესრულებაზე.  
                </li> 
                </ul>
            </div>
            <div class="benefits-box img-wrap right">
                <img src="/images/benefit/image3.jpg"/>
            </div>
         
        </div>
        <div class="benefits-boxes">
          
            <div class="benefits-box img-wrap ">
                <img src="/images/benefit/image4.jpg?v1"/>
            </div>
            <div class="benefits-box right-text has-text font-13">
                <ul>
                <li class="text-uppercase">
                    ჯანმრთელობა და თანამშრომლებზე ზრუნვა
                </li>
                <li class="desc">
                  შენი ჯანმრთელობა ჩვენი უმთავრესი პრიორიტეტია.
                </li>
                <li>
                  1. თიბისი დაზღვევა
                </li>
                <li>
                  სარგებლობ ჯანმრთელობის საუკეთესო დაზღვევით.
                </li>
                <li>
                  2. Fitpass პროგრამა
                </li>
                <li>
                  შენ და შენს ოჯახის წევრებს შეგიძლიათ Fitpass მომსახურების ფარგლებში 40-ზე მეტ სპორტულ აქტივობაში ჩართვა,
                   საქართველოს მასშტაბით 150-მდე ობიექტში.  
                </li>
                <li>
                 3. კვება 
                </li>
                <li>
                  ყოველ ორშაბათს ოფისში დაგხვდება ჯანსაღი საუზმე, პარასკევს ხილი და ბოსტნეულის ჩხირები. ღამის ცვლაში მუშაობისას კი ყოველდღიურად დაგვხვდება სხვადასხვა გემრიელობა. 
                </li>
                <li>
                  4. ჯანსაღი პარასკევი
                </li>
                <li>
                  ყოველ პარასკევს მიირთმევ ხილს და ბოსტნეულის ჩხირებს.
                </li>
                <li>
                  5. ჯანსაღი საუზმე
                </li>
                <li>
                  კვირაში 4 დღე გიმასპინძლდებით ჯანსაღი საუზმით.
                </li>
                <li>
                  6. სენდვიჩები ღამის ცვლაში
                </li>
                <li>
                 ღამის ცვლაში ყოველდღიურად გხვდება სენდვიჩები და სხვადასხვა გემრიელობა.
                </li>


                </ul>
            </div>
        </div>
        <div class="benefits-boxes reverse"> 
             <div class="benefits-box right-text has-text">
                <ul>
                <li class="text-uppercase">
                  გართობა და დასვენება
                </li>
                <li class="desc">
                 ევროპაბეთში არსებული დასვენების სივრცე შენი სამუშაო დღის შემსუბუქებაზე, 
                 პროდუქტიულობის ზრდაზე და გუნდური მუშაობის გაუმჯობესებაზეა გათვლილი.
                </li>
                <li>
                  1. არანაირი დრესკოდი 
                </li>
                <li>
                  გაცვია ის, რაც გინდა. ნებისმიერი სტილი მისაღებია.
                </li>
                <li>
                  2. კორპორატიული ივენთი
                </li>
                <li>
                  ჩაერთე კორპორატიულ ღონისძიებაში წელიწადში ორჯერ.
                </li> 
                <li>
                  3. რელაქს ზონა
                </li>
                <li>
                  განტვირთვისათვის ეწვიე სათამაშო-გასართობ სივცეს.
                </li> 
                <li>
                  4. საოფისე ღონისძიებები
                </li>
                <li>
                  ჩაერთე eteam-ის წევრების ინიცირებულ საოფისე ღონისძიებებში.
                </li> 
                </ul>
            </div>
            <div class="benefits-box img-wrap right">
              <img src="/images/benefit/image5.jpg"/>
            </div> 
        </div>
        <div class="benefits-boxes"> 
            <div class="benefits-box img-wrap ">
              <img src="/images/benefit/image6.jpg"/>
            </div>
            <div class="benefits-box right-text has-text">
                <ul>
                <li class="text-uppercase">
                  პერსონალური განვითარება
                </li>
                <li class="desc">
                  მუდმივად გეძლევა განვითარების და ახალი კარიერული ხედვების განხორციელების შესაძლებლობები.
                </li>
                <li>
                  1.  ბორდრუმ ბიბლიოთეკა 
                </li>
                <li>
                  აღმოაჩინე ბიბლიოთეკა, რომელიც შენს პიროვნულ და პროფესიულ განვითარებაზეა ორიენტირებული. 
                </li>
                <li>
                  2. თიმბილდინგი
                </li>
                <li>
                  ჩაერთე ყოველწლიურ თიმბილდინგებში და უკეთ გაიცანი შენი თანამშრომლები.
                </li> 
                <li>
                 3.	თემატური მასტერკლასი
                </li>
                <li>
                  გაიარე თემატური მასტერკლასები, რომლებიც პროფესიულ ზრდაში დაგეხმარება.
                </li> 
                </ul>
            </div> 
        </div>
        <div class="benefits-boxes reverse">
             <div class="benefits-box right-text has-text">
                <ul>
                <li class="text-uppercase">
                    ინტერესთა კლუბები
                </li>
                <li class="desc">
                    კლუბები გეხმარება საერთო ინტერესის მქონე ადამიანების გაცნობასა და საინტერესო აქტივობების ჩატარებაში.<br/> 
                    ევროპაბეთში შეძლებ ისეთ ინტერესთა კლუბებში გაერთიანებას როგორიცაა: 
                    ლაშქრობის მოყვარულთა, მკითხველთა, ცხოველებზე მზრუნველების, ფილმებისა და სერიალების, 
                    მოყვარული კულინარების, ჭადრაკის, ინტელექტუალური თამაშების, კალათბურთის, 
                    ფეხბურთის და პოკერის კლუბები.
                </li>
                
                </ul>
            </div>
            <div class="benefits-box img-wrap right">
                <img src="/images/benefit/image7.jpg"/>
            </div>
            
       
         
        </div> 
        <div class="benefits-boxes"> 
            <div class="benefits-box img-wrap ">
               <img src="/images/benefit/image8.jpg"/>
            </div>
            <div class="benefits-box right-text has-text">
                <ul>
                <li class="text-uppercase">
                  დამატებითი ბენეფიტები
                </li>
                <li>
                 
                </li>
                <li>
                 1.	კორპორატიული ტაქსი
                </li>
                <li>
                  ღამის ცვლაში მომუშავე eteam-ის წევრებს ემსახურება კორპორატიული ტაქსი.
                </li>
                <li>
                 2.	კორპორატიული ქსელი
                </li>
                <li>
                  განსაზღვრულ პოზიციებზე დასაქმებული თანამშრომლები სარგებლობენ კორპორატიული მობილური კავშირით.
                </li> 
                 
                </ul>
            </div> 
        </div>
           <div class="benefits-boxes reverse">
             <div class="benefits-box right-text has-text last">
                <ul  >
                <li class="text-uppercase">
                   დასაქმების ფორუმები
                </li>
                <li>
                    <p> 
                        ვცდილობთ რომ ყველა დასაქმების ფორუმზე ჩვენი სტენდი დაუვიწყარი იყოს, 
                        გვსურს რომ ბევრს მოვუყვეთ ჩვენი კორპორატიული კულტურის შესახებ და ერთად გავერთოთ. 
                        ჩვენი სტილი დასაქმების ფორუმებზე - საინტერესო, დაუვიწყარი და სახალისოა.  
                    </p>
                    <p> 
                        2019 წელი - გადავწყვიტეთ რომ თავისუფალ უნივერსიტეტში, სტუდენტებისთვის <a target="_blank" href="https://www.linkedin.com/posts/europebet_beqbeobepbesberbes-vacancies-discover-activity-6524253792899338241-RyBw/?utm_source=linkedin_share&utm_medium=member_desktop_web">თავსატეხები</a>
                        მიგვეტანა და გამარჯვებულებისთვის ჩვენი ბრენდირებული საჩუქრები გადაგვეცა, ასევე ჩავატარეთ <a target="_blank" href="https://www.instagram.com/tv/B6ifI5hHUpQ/?igshid=YmMyMTA2M2Y=">VR ჩელენჯი</a> 
                        რადგან გვსურდა სტუდენტებს ცოდნოდათ რას ნიშნავს სწრაფად განვითარებად და ინოვაციურ კომპანიაში მუშაობა. 
                    </p>
                    <p> 
                        2020 წელი - პანდემიის პირობებში დიდი გამოწვევის წინაში აღმოვჩნდით, თუმცა მალევე ვიპოვეთ გამოსავალი 
                        და მონაწილეებს ზუმში <a target="_blank" href="https://www.linkedin.com/posts/europebet_%E1%83%A9%E1%83%95%E1%83%94%E1%83%9C%E1%83%98-%E1%83%A8%E1%83%94%E1%83%A0%E1%83%A9%E1%83%94%E1%83%95%E1%83%98%E1%83%A1-%E1%83%92%E1%83%A3%E1%83%9C%E1%83%93%E1%83%98%E1%83%A1-%E1%83%AC%E1%83%94%E1%83%95%E1%83%A0%E1%83%94%E1%83%91%E1%83%9B%E1%83%90-tika-ksovreli-activity-6760898893728362496-uT2Y/?utm_source=linkedin_share&utm_medium=member_desktop_web">ფაზლი</a> ავაწყობინეთ, ყველაზე სწრაფი 10 სტუდენტი კი ტრადიციულად დავასაჩუქრეთ.
                        2022 წელი - 1 წლიანი იძულებული შესვენების შემდეგ, <a target="_blank" href="https://www.linkedin.com/feed/update/urn:li:activity:6924630116463464448">რეტრო თამაშებით</a> დავბრუნდით თანაც წელს 
                        განსაკუთრებული პრიზები დავაწესეთ და ჩვენ ბრენდირებულ საჩუქრებთან ერთად გამარჯვებულები Macbook, 
                        Airpods და AppleWatch-ით დავაჯილდოვეთ.
                    </p>
                <div class="dotted-wrapper">
                    <span>2019 - თავსატეხები / ჩვენი სტილი & სტერეოტიპები </span>
                    <span>2020 - ფაზლის აწყობა ზუმში    </span>
                    <span>2022 - რეტრო თამაშების შეჯიბრი    </span>
                    <span>2023 - Subsoccer-ის ჩემპიონატი</span>
                    <span>2024 - მაგიდის ფეხბურთის ჩემპიონატი </span>
                </div>

                </li>
                
                </ul>
            </div>
            <div class="benefits-box img-wrap right">
             <img src="/images/benefit/image9.jpg"/>
            </div>
            
       
         
        </div>
    </div>
    <div class="benefits-footer text-uppercase">
            დაინტერესდი eteam-ით? ეწვიე ჩვენს <a  href="/jobs">დასაქმების გვერდს.</a>
    </div>
   <div class="bottom-carousel">
        <carousel :autoplay="true"  :items="5" :margin="15" :autoplayTimeout="3000" :loop="true" :dots="false" :responsive="{0:{items:2,nav:false},600:{items:3,nav:false},992:{items:9,nav:false}}">
            <img src="/images/smslider/image1-min.jpg">
            <img src="/images/smslider/image2-min.jpg">
            <img src="/images/smslider/image3-min.jpg">
            <img src="/images/smslider/image4-min.jpg">
            <img src="/images/smslider/image5-min.jpg">
            <img src="/images/smslider/image6-min.jpg">
            <img src="/images/smslider/image9-min.jpg">
            <img src="/images/smslider/image10-min.jpg">
            <img src="/images/smslider/image11-min.jpg">
            <img src="/images/smslider/image12-min.jpg">
            <img src="/images/smslider/image13-min.jpg">
            <img src="/images/smslider/image15-min.jpg">
        
        </carousel>
   </div>
</div>


<my-footer></my-footer>
</div>
</template>
<script>
import Header  from '../components/Header.vue';
import Footer  from '../components/Footer.vue';

import carousel from 'vue-owl-carousel'


export default {
  components: {
   'myHeader': Header,
   'myFooter': Footer,
   carousel
  },
  created() {
    
  }, 
  data () {
    return {
       
         
    }
  },
  methods: {

  },
  computed:{
   
 },
 mounted(){

    

 }
};
 
</script>
<style lang="scss">
.benefits-footer{
        box-shadow: 0px -50px 40px 0px #060608;
    text-align: center;
    color:#fff;
    font-size: 18px;
    padding:20px 0 80px 0;
    a{
        color:#F05A22;
    }
}
.main-title{
   text-align: center;
   color:#fff;
}
.benefits-container{
    font-family: 'helv-55'; 
    color:#A2A2A2;
    margin-top: 60px; 
    line-height: 1.3;
    background-image: url('/images/benefits-bg.png');
    background-repeat-y: repeat;
    background-position: top center;
    background-size: contain;

    .benefits-boxes{
        width:100%;
        margin:50px 0;
        display: inline-flex;
        justify-content: center;
        align-items: stretch;
       .benefits-box{
        .desc{
           font-size: 16px;
        } 
        font-size: 13px; 
        a{
          color:#F05A22;
        }
         
         width:50%;
        // border:1px solid green;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        .dotted-wrapper{
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            span{ 
                position: relative;
                display: inline-flex;
                align-items: center;
                padding-left: 10px;
                &:before{
                    left:0px;
                    content:"";
                    width:4px;
                    height: 4px;
                    background-color: #FF3A00;
                    border-radius: 50%;
                    position:absolute;
                }
            }
        }

        &.has-text{
             ul{
                    margin-left: 11%;
                    margin-right: 12%;
                    width:100%;
                li{
                    margin-bottom: 8px;
                    &:nth-child(odd){
                        margin-bottom: 4px;
                        color: #fff;
                    }
                }
            }
        }
       
        &.img-wrap{ 
            justify-content: flex-start;
            img{
            
                max-width: 89%;
                border-top-right-radius: 11px;
                border-bottom-right-radius: 11px;
            }
            &.right{
              justify-content: flex-end;
              img{
                border-radius: 0;
                 border-top-left-radius: 11px;
                border-bottom-left-radius: 11px;
              }
            }
        }
        &.first{
          
            ul{
                width: 85%;
                li{ 
                    margin-bottom: 8px;
                    &:first-child{
                        font-size: 16px;
                    }
                }
            }
            a{
                color:#F05A22;
                text-decoration: underline;
            }
        }
        &.last{
            p{
                margin-bottom:10px;
            }
          ul{
            margin-right: 12%;
          }
        }
       }
        
    }
 
    @media only screen and (max-width:1400px){
 .benefits-boxes .benefits-box.first ul li,.benefits-boxes .benefits-box.first ul li:first-child{
  font-size: 13px;
}
    }
    @media only screen and (max-width:1280px){
      font-size: 14px;
      .benefits-boxes{
        margin:30px 0;
        .benefits-box{
           align-items: flex-start;
          .last ul{
            margin-left: 8%;
            margin-right: 2%;
          }
          &.img-wrap{
            align-items: flex-start;
            img{
                max-width: 92%;
            } 
          }
          &.has-text ul {
              margin-left: 8%;
          }
        }
      }
    }
     @media only screen and (max-width:800px){
      
      .benefits-boxes{
        flex-direction: column;
        .benefits-box{
          width:100%;
          &.first{
            ul{
              width:calc(100% - 48px);
            }
          }
          &.img-wrap{
            margin-bottom: 24px;
            justify-content: center; 
                img{
                  max-width: calc(100% - 48px);
                  border-radius: 20px;
                } 
            &.right{
              justify-content: center;
                img{
                  max-width: calc(100% - 48px);
                  border-radius: 20px;
                }
            }
          
          }
          &.has-text{
            ul{
              margin-left: 24px;
            }
          }
        }
        &.reverse{
          flex-direction: column-reverse;
        }
      }
     }
}
@font-face {
    font-family: 'helv-55';
    src: url('../../assets/fonts/helv-55.otf');
}
@font-face {
    font-family: 'helv-65';
    src: url('../../assets/fonts/helv-65.otf');
}
@font-face {
    font-family: 'helv-75';
    src: url('../../assets/fonts/helv-75.otf');
}
@font-face {
    font-family: 'industry-2018';
    src: url('../../assets/fonts/Industry2018-Ultra.ttf');
}
.text-uppercase{
      font-family: 'helv-75';
      font-size: 18px;
}
a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,output,p,pre,q,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:after,blockquote:before,q:after,q:before{content:'';content:none}table{border-collapse:collapse;border-spacing:0}
body {
     background: #060608 !important;
}
.container {
  margin: 0 auto;
  @media (min-width: 992px) {
    width: 960px;
  }
   @media (min-width: 1200px) {
    width: 1140px;
  }
  @media (min-width: 1400px) {
    width: 1360px;
  }
}
.banner-row-item {
    width: 100%;
    display: flex;

    &.has-bg{
          padding-top: 26%; 
 
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    @media only screen and (max-width:768px){
  padding-top: 46%;
}
    }

    &.about{
         background-image: url('/images/aboutcover.png');
    }
    &.benefits{
         background-image: url('/images/benefits/benefitscover.png');
    }

   

}
.benefits-wrapper {
    background-image: url('/images/bgabout.png');
    background-position: 120px -400px;
    background-size: contain;
    background-color: #060608;
    background-repeat: no-repeat;
    @media (max-width: 991px) {
        background-image: none;
    }
    .heading {
        text-align: center;
        padding-top: 50px;
            @media (max-width: 757px) {
                text-align: left;
                padding-top: 25px;
            }
        h4 {
            font-size: 24px;
            color: #fff;
            font-family: 'helv-65';
            padding: 0 15px;
            line-height: 32px;
            max-width: 70%;
            margin: 0 auto 15px;
             @media (max-width: 757px) {
                font-size: 12px;
                font-family: 'helv-55';
                max-width: unset;
                line-height: 1.5;
            }
        }
    }
    .benefit-list {
        margin-top: 50px;
        .item {
            display: flex;
            align-items: center;
            margin-bottom: 100px;
            @media (max-width: 767px) {
                flex-direction: column;
                padding: 0 15px;
                margin-bottom: 50px;
            }
            img {
                width: 50%;
                border-radius: 20px;
                 @media (max-width: 767px) {
                   width: 100%;
                   order: unset !important
                }
            }
            &:nth-child(2n) {
                img {
                    order: 2
                }
                .details {
                    order: 1;
                }
            }
            .details {
                margin-left: 30px;
                width: 50%;
                padding-right: 50px;
                @media (max-width: 767px) {
                    width: 100%;
                    margin-top: 25px;
                    margin-left: 0;
                    padding-right: 0;
                }
                h4 {
                    font-size: 24px;
                    color: #fff;
                    font-family: 'industry-2018';
                     @media (max-width: 767px) {
                        font-size: 18px;
                    }
                }
                ul {
                    margin-top: 15px;
                    li {
                        color:#A2A2A2;
                        font-size: 14px;
                        font-family: 'helv-55';
                        position: relative;
                        margin-bottom: 10px;
                        line-height: 1.3;
                        padding-left: 5px;

                        @media (max-width: 767px) {
                            word-break: break-all;
                            padding-right: 15px;
                        }

                        &::before {
                            content: '';
                            width: 4px;
                            height: 4px;
                            background: #F05A22;
                            border-radius: 100%;
                            position: absolute;
                            left: -5px;
                            top: 7px;
                        }
                    }
                }
                p {
                    color:#A2A2A2;
                    font-size: 14px;
                    font-family: 'helv-55';
                    line-height: 1.3;
                    margin-top: 15px;

                    a {
                        color: #F05A22;
                        text-decoration: none;
                    }
                }
            }
        }
    }
  
}
  .bottom-carousel {
        background: #19191A;
        padding-top: 100px;
        padding-bottom: 100px;
        @media (max-width: 991px) {
            padding-top: 50px;
            padding-bottom: 50px;  
        }
        .owl-item img {
            border-radius: 8px;
        }
    }
.benefitspage .footer-wrapper {
    margin-top: 0
}
 
</style>
